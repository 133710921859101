.statLabel {
  font-size: 12;
  color: gray;
  font-weight: 500;
  font-family: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';
  margin: 0;
}

.statValue {
  font-size: 20;
  font-weight: 'bold';
  margin-bottom: 4;
  letter-spacing: '1px';
}
